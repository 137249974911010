
import headerMenu from "@/headerMenu.json";

export default {
  data() {
    return {
      showMenu: false,
      header: headerMenu,
      activeUrl: this.$route.path,
    };
  },
  watch: {
    $route(to, from) {
      this.showMenu = false;
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },

  computed: {},
};
