
import footerMenu from "@/footerMenu.json";

export default {
  data() {
    return {
      footer: footerMenu,
    };
  },
};
