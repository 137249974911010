
import site_settings from "@/site-settings.json";

export default {
  name: "default",
  beforeMount() {
    this.bodyScript()
    // this.headScript()
  },
  methods: {
    headScript() {
      if (site_settings.headerSettings.codeInjection && site_settings.headerSettings.codeInjection.length > 0) {
        document.head.insertAdjacentHTML('beforeend', site_settings.headerSettings.codeInjection)
      }
    },
    bodyScript() {
      if (site_settings.footerSettings.codeInjection && site_settings.footerSettings.codeInjection.length > 0) {
        document.body.insertAdjacentHTML("afterbegin", site_settings.footerSettings.codeInjection);
      }
    },
  }
};
