
export default {
  name: "Picture",
  props: {
    image: {
      type: Object,
      default() {},
    },
  },
};
